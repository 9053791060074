import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux"
import { useLocation } from "react-router-dom";
import ACTION_LOADING_SPINNER_ACTIVE from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_ACTIVE"
import "./index.scss"
import ACTION_LOADING_SPINNER_RESET from "../../../actions/LoadingSpinner/ACTION_LOADING_SPINNER_RESET";
import Cookies, { set } from "js-cookie";
import { Redirect } from "react-router-dom";
import moment from "moment"
import {useMutation, useLazyQuery} from "@apollo/client"
import noWidget from "../../../assests/images/noWidget.svg"
import addIcon from "../../../assests/images/addIcon.svg"
import addViewPink from "../../../assests/images/addViewPink.svg"
import editIcon from "../../../assests/images/editIcon.svg"
import shareIcon from "../../../assests/images/shareIcon.svg"
import deleteIcon from "../../../assests/images/deleteIcon.svg"
import {DataSpinner} from "../../resuable/spinner/dataSpinner/index"
import AddClientModal from "../../reusable/addClientModal";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import addClientMutation from "../../../graphql/mutations/client/addClientMutation";
import updateClientMutation from "../../../graphql/mutations/client/updateClientMutation";
import deleteClientMutation from "../../../graphql/mutations/client/deleteClientMutation";
import clientQuery from "../../../graphql/queries/client/clientQuery";
import AccountHeader from "./accountHeader"
import ACTION_PHONE_NUMBER from "../../../actions/GuestCheckoutForm/PhoneNumber/ACTION_PHONE_NUMBER";
import UserFeedback from "./userFeedback";
import "react-dropdown/style.css";
import "react-day-picker/lib/style.css";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import Modal from "react-modal"
import Dropdown from "react-dropdown";
import imageCompression from "browser-image-compression";
import LZString from "lz-string";
import ACTION_PHONE_NUMBER_RESET from "../../../actions/GuestCheckoutForm/PhoneNumber/ACTION_PHONE_NUMBER_RESET";

 
const AdminCampaign = ({user, activeClient, setActiveClient, client, clientData, clientRefetch, clientLoading}) => {

  const phoneNumber = useSelector((state) => state.phoneNumber.phone_number);

    let location = useLocation()
    const { pathname } = useLocation();

    const returnToHomeScreen = () => {
        if(!Cookies.get("dummy-token")) {
            return <Redirect to="/admin" />
        }
    }


    const dispatch = useDispatch()


    const [clientModal, setClientModal] = useState(null)
    const [editClientModal, setEditClientModal] = useState(null)
    const [clientName, setClientName] = useState("")
    const [clientEmail, setClientEmail] = useState("")
    const [clientMobile, setClientMobile] = useState("")
    const [clientLocation, setClientLocation] = useState("")
    const [contactPersonName, setContactPersonName] = useState("")
    const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [selectedClient, setSelectedClient] = useState(null)


    const [
      addClient,
      { loading:addClientLoading, data:addClientData },
    ] = useMutation(addClientMutation);
  
    const [
      updateClient,
      { loading:updateClientLoading, data:updateClientData },
    ] = useMutation(updateClientMutation);

    const [dataArr, setDataArr] = useState(null)
    const [many, setMany] = useState(false)


    const inviteManyUsers = (many) => new Promise(async (resolve, reject) => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setClientModal(null)
     addClient({
        variables:{
            many: JSON.stringify(many)
        }
      }).then(res => {
        if(res.data.addClient) {
          setClientModal(null)
          setDataArr(null)
          setMany(false)
          dispatch(ACTION_LOADING_SPINNER_RESET())
          history.push("/admin/manage")
        } 
      }).catch(err => {
        if(err) {
          dispatch(ACTION_LOADING_SPINNER_RESET())
          dispatch(ACTION_PHONE_NUMBER_RESET())
          setDataArr(null)
          setMany(false) 
        }
      })
    })



    const inviteUser = (type) => {
      dispatch(ACTION_LOADING_SPINNER_ACTIVE())
      setClientModal(null)
      if(false) {

       
      } else {
      addClient({
        variables:{
            name: clientName,
            phoneNumber: phoneNumber,
            location: clientLocation ? clientLocation : "no preference",
            type: clientType,
            employee: user?.user?._id
        }
      })
      .then((res) => {
        changeImageUploaded("");
        setAddProfileClicked(false)
        changeImagePreviewAvailable(false);
        changeTakeAPhotoSelected(false);
        changeWebcamURI("");
        setClientName("")
        setClientEmail("")
        setClientMobile("")
        setContactPersonName("")
        dispatch(ACTION_PHONE_NUMBER_RESET())
        if(user?.user?.role == "sales") {
          client({
          variables:{
            _id: res?.data?.addClient?._id
          }
        })
        }
        })
      }
    }


    // photo

     
    const [takeAPhotoSelected, changeTakeAPhotoSelected] = useState(false);
    const [webcamURI, changeWebcamURI] = useState("");
    const [imageUploaded, changeImageUploaded] = useState("");
    const [imagePreviewAvailable, changeImagePreviewAvailable] = useState(false);
    const [addProfilePhotoClicked, setAddProfileClicked] = useState(false)

    
    const handleDeletedPreviewImage = () => {
        const deleteImageClass = document.getElementsByClassName("deleteImage");
        const uploadPictureClass = document.getElementsByClassName("uploadPicture");
    
        if (deleteImageClass) {
          if (deleteImageClass[0]) {
            deleteImageClass[0].style.display = "none";
          }
        }
        if (uploadPictureClass) {
          if (uploadPictureClass[0]) {
            uploadPictureClass[0].style.display = "none";
          }
        }
      };
    
  const handleImageUploaded = async (picture) => {
    // dispatch(ACTION_IMAGE_LOADING());
    if (picture[0] || typeof picture === "string") {
      const reader = new FileReader();
      changeImagePreviewAvailable(true);
      try {
        let compressedImage;

        if (typeof picture === "object") {
          compressedImage = await imageCompression(picture[0], {
            maxSizeMB: 0.3,
            maxWidthOrHeight: 300,
          });
        } else if (typeof picture === "string") {
          await fetch(picture)
            .then((res) => {
              return res.blob();
            })
            .then(async (blob) => {
              compressedImage = await imageCompression(blob, {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 300,
              });
            });
        }

        reader.readAsDataURL(compressedImage);

        reader.onloadend = async () => {
          const base64data = reader.result;

          const compressedBase64data = LZString.compressToEncodedURIComponent(
            base64data
          );
        //   dispatch(ACTION_IMAGE_LOADING_RESET());
          changeImageUploaded(compressedBase64data);
        };
      } catch (error) {
        // dispatch(ACTION_IMAGE_LOADING_RESET());
        console.log(error);
      }
    } else {
    //   dispatch(ACTION_IMAGE_LOADING_RESET());
      changeImageUploaded("");
      changeImagePreviewAvailable(false);
      handleDeletedPreviewImage();
    }
  };



  useEffect(() => {
    if(addClientData) {
      dispatch(ACTION_LOADING_SPINNER_RESET())
    }
  },[addClientData])


const editClient = (client) => {  
  dispatch(ACTION_PHONE_NUMBER(client.phoneNumber))
  setClientName(client.name)
  setClientLocation(client.location)
  setEditClientModal("Edit Client") 
}

const [
  deleteClient,
  { data: deleteClientData },
] = useMutation(deleteClientMutation)


const deleteClientFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())

    deleteClient({
      variables:{
        _id: activeClient._id,
      }
    }).then(() => {
     if(user.user.role == "sales") { 
       client({
        variables:{
          employee: user.user._id
        }
      })}
      dispatch(ACTION_LOADING_SPINNER_RESET())
    })
}


const updateClientFunc = () => {
  dispatch(ACTION_LOADING_SPINNER_ACTIVE())
  setEditClientModal(null)
  updateClient({
    variables:{
      _id: activeClient._id,
      image: imageUploaded ? imageUploaded : null,
      location: clientLocation,
      name: clientName
    }
  }).then(clientRefetch())
}
 

// feedback modal




const [feedbackModel, setFeedbackModal] = useState(false)

const feedbackOptions = [
  {value: "booked", label: "Booked"},
  {value: "followUp", label: "Follow up" },
  {value: "overPrices", label: "Over prices" },
  {value: "notInterested", label: "Not interested" },
  {value: "joinedAnotherEms", label: "joined another EMS" },
  {value: "arrived", label: "Arrived"},
  {value: "noShow", label: "No show" },
  {value: "noAnswer", label: "No answer" },
  {value: "medical", label: "Medical"},
  {value: "underAge", label: "Under age"},
  {value: "willCallUs", label: "Will call us"},
  {value: "lowClass", label: "Low class"},
  {value: "askAboutPrices", label: "Ask about prices"},
  {value: "askAboutJob", label: "Ask about job"},
  {value: "alreadyMember", label: "Already member"},
  {value: "wrongNumber", label: "Wrong nubmer"},
  {value: "overWeight", label: "Over weight"},
  {value: "switchedOff", label: "Switched off"},
  {value: "followToBook", label: "Follow to book"},
  {value: "travell", label: "Travell"},
  {value: "renew", label: "Renew"},
  {value: "cancelled", label: "Cancelled"},
  {value: "repeated", label: "Repeated"},
  {value: "willVisitTheBranch", label: "Will visit the branch"},
  {value: "callBack", label: "call back"},
  {value: "farAway", label: "far Away"},
  {value: "whatsappSend", label: "whatsapp send"},
]

const [feedback, setFeedback] = useState("")
const [feedbackNotes, setFeedbackNotes] = useState("")
const [feedbackDate, setFeedbackDate] = useState("")
const [clickOutsideDayPickerEnd, changeClickOutsideDayPickerEnd] = useState(true);
const [clientType, setClientType] = useState("")

useEffect(() => {
const dayPickerClickFunctionEnd = (e) => {
  if (e.target) {
    if (e.target.placeholder === "Feedback date") {
      if (clickOutsideDayPickerEnd) {
        changeClickOutsideDayPickerEnd(false);
      }
    } else if (e.target.getAttribute("class")) {
      if (typeof (e.target.className === "string")) {
        if (!e.target.className.baseVal) {
          if (
            e?.target?.className?.split(" ")?.includes("DayPicker-Day") ||
            e?.target?.className?.split(" ")?.includes("DayPicker-NavButton")
          ) {
            if (clickOutsideDayPickerEnd) {
              changeClickOutsideDayPickerEnd(false);
            }
          } else {
            if (!clickOutsideDayPickerEnd) {
              changeClickOutsideDayPickerEnd(true);
            }
          }
        } else {
          if (!clickOutsideDayPickerEnd) {
            changeClickOutsideDayPickerEnd(true);
          }
        }
      } else {
        if (!clickOutsideDayPickerEnd) {
          changeClickOutsideDayPickerEnd(true);
        }
      }
    } else {
      if (!clickOutsideDayPickerEnd) {
        changeClickOutsideDayPickerEnd(true);
      }
    }
  } 
};

window.addEventListener("click", dayPickerClickFunctionEnd);

return () => {
  window.removeEventListener("click", dayPickerClickFunctionEnd);
};
}, [clickOutsideDayPickerEnd]);

const timeOptions = () => {
const minutesArr = ["00", "15", "30", "45"];
const allTimeArr = [];

for (let i = 10; i < 22; i++) {
  for (let j = 0; j < minutesArr.length; j++) {
    allTimeArr.push(
      (i > 12 ? i - 12 : i === 0 ? "12" : i) +
        ":" +
        minutesArr[j] +
        " " +
        (i > 11 ? "PM" : "AM")
    );
  }
}
return allTimeArr;
};

const locationOptions = () => {
const allLocations = ["Maadi", "Madinty", "Zayd", "tagamoa", "Heliopolis"]
return allLocations;
};

const [feedbackTime , setFeedbackTime] = useState("")
const [feedbackLocation, setFeedbackLocation] = useState("") 

const addUserFeedback = () => {

dispatch(ACTION_LOADING_SPINNER_ACTIVE())

let clientNumberOnSpot = activeClient?.phoneNumber?.replace(/[^0-9]/g,'')
let clientName = client.name
 let clientt = {}
 clientt._id  = activeClient._id
 clientt.state = feedback
 clientt.location = feedbackLocation
 clientt.employee = user.user._id
 clientt.nextCall = feedbackNotes 
 clientt.image = feedbackDate ? moment(
moment(feedbackDate, "MM/DD/YYYY").format("MMMM DD, YYYY") +
" " +
feedbackTime 

).add(feedback == "booked" ? "45" : "5", "minute").toDate().toString() : feedback == "noAnswer" || feedback == "cancelled" || feedback == "switchedOff" ? moment(new Date(), "MM/DD/YYYY hh:mm A").add(3, "hours").toDate().toString() : null
setFeedbackModal(false)


updateClient({
  variables:{
    ...clientt
    }
}).then(res=>{

  if(feedback == "booked") {
      
    var whatsappMessage= `Hello ${clientName}! 
BodyFit welcomes you as its newest family member, you have booked a trial session on ${moment(
moment(feedbackDate, "MM/DD/YYYY").format("MMMM DD, YYYY") +
" " +
feedbackTime 

).format("MMMM DD, YYYY hh:mm A")} at ${feedbackLocation}
We will confirm the appointment 1 day before from ${feedbackLocation} branch Number : ${feedbackLocation == "Maadi" ? "01004100023" : feedbackLocation == "Madinty" ? "01099956505" : feedbackLocation == "Zayd" ? "01030088883" : feedbackLocation == "tagamoa" ? "01121227700" : feedbackLocation == "Heliopolis" ? "01003398831" : null}

    1 month ( 4 sessions ) 2000  EGP Valid for 40 days 
    2 months  ( 8 sessions ) 3500 EGP  Valid for 60 days 
    3 months (12 sessions ) 4900 EGP EGP valid for 100 days ••
    6 months (24 sessions) 7800 EGP EGP valid for 200 days
    9 months (36 sessions) 10600 EGP EGP valid for 300 days
    One Year Package ( 48 sessions ) 12900 EGP Valid for One Year only 


All memberships include: 
1-Free trial session (Free per session for active members) incase you won't buy a package after the session you will pay 400 LE only
2- Free invitations to a friend
3-Personal trainer 
4-free diet plan 
5-free inbody free undersuit to use
We wish you a rewarding, unforgettable fitness journey at Body Fit! 

${feedbackLocation == "Maadi" ? "https://www.google.com/maps?q=29.9690861,31.2711326&z=17&hl=en" : feedbackLocation == "Madinty" ? "https://maps.app.goo.gl/9FugQQc6eh3ZYUnH7?g_st=iwb" : feedbackLocation == "Zayd" ? "https://www.google.com/maps?q=30.0326866,30.9845615&z=17&hl=en" : feedbackLocation == "tagamoa" ? "https://www.google.com/maps?q=30.019152,31.4349172&z=17&hl=en" : feedbackLocation == "Heliopolis" ? "https://www.google.com/maps?q=30.099740982055664,31.317909240722656&z=17&hl=en" : null}
`
let message = window.encodeURIComponent(whatsappMessage)
window.open(`https://api.whatsapp.com/send?phone=+2${clientNumberOnSpot}&text=${message}`, "_blank")

   } else if (feedback == "noAnswer" || feedback == "switchedOff" || feedback == "cancelled") {
    var whatsappMessage= `Hello ${clientName}! 

Its Bodyfit ems Egypt team!
We’ve been trying to reach you for a while but it seems like you’re busy.
What time is best for you to receive our call?
Waiting for your reply and thank you in advance.`

let message = window.encodeURIComponent(whatsappMessage)
window.open(`https://api.whatsapp.com/send?phone=+2${clientNumberOnSpot}&text=${message}`, "_blank")

   } else if (feedback == "whatsappSend") {
    var whatsappMessage= `Hello its bodyfit ems egyot team !
    Have you checked Body Fit’s newest membership programs? Our new packages prices , that's a golden opportunity for all to reach your goals in 20 minutes per session! Besides our new trial session that is now for FREE when you get our package, in case you’d like to go for a trial session only without a package, you’ll only pay the trial session fees.
    
    1 month ( 4 sessions ) 2000  EGP Valid for 40 days 
    2 months  ( 8 sessions ) 3500 EGP  Valid for 60 days 
    3 months (12 sessions ) 4900 EGP EGP valid for 100 days ••
    6 months (24 sessions) 7800 EGP EGP valid for 200 days
    9 months (36 sessions) 10600 EGP EGP valid for 300 days
    One Year Package ( 48 sessions ) 12900 EGP Valid for One Year only 
    
    Our offers don’t end here! If you bring a friend or a relative along, you get an EXTRA discount We wish you a rewarding, unforgettable fitness journey at Body Fit. Join today!`
let message = window.encodeURIComponent(whatsappMessage)
window.open(`https://api.whatsapp.com/send?phone=+2${clientNumberOnSpot}&text=${message}`, "_blank")

   } else if (feedback == "noShow") {
    var whatsappMessage= `Hello Its Bodyfit ems Egypt team!
    We wanted to remind you that you missed your scheduled appointment with us.
    We understand that things come up and plan change but still there is a chance to achieve our goal 
    If you would like to reschedule the missed appointment, please call us reply to this message to schedule a new appointment. Waiting for you response and thank you in advance. `
let message = window.encodeURIComponent(whatsappMessage)
window.open(`https://api.whatsapp.com/send?phone=+2${clientNumberOnSpot}&text=${message}`, "_blank")

   } else if (feedback == "willCallUs") {
    var whatsappMessage= `Hello Its Bodyfit ems Egypt team !
    We're so excited that you're interested in booking an appointment with us! Our team is ready to help you look and feel your best, but before you give us a call, we wanted to let you know that booking an appointment with us is like embarking on a journey to a new version of yourself. We'll be your guides and partners in this journey, and we promise it'll be worth it.
    When you call to book your appointment, please have your calendar handy so we can find a time that works best for you. We'll also be happy to answer any questions you have about our services and what you can expect from us.
    -Body fit EMS family `
let message = window.encodeURIComponent(whatsappMessage)
window.open(`https://api.whatsapp.com/send?phone=+2${clientNumberOnSpot}&text=${message}`, "_blank")

   } else if (feedback == "willVisitTheBranch") {
    var whatsappMessage= `Hello Its Bodyfit ems Egypt team  !
    We are excited to have you visit our branch. Our team is looking forward to assisting you with your needs and answering any questions . We look forward to seeing you soon!
    Branchs location :
    https://www.google.com/maps?q=29.9690861,31.2711326&z=17&hl=en
    https://www.google.com/maps?q=30.0326866,30.9845615&z=17&hl=en
    https://www.google.com/maps?q=30.019152,31.4349172&z=17&hl=en
    https://www.google.com/maps?q=30.099740982055664,31.317909240722656&z=17&hl=en
    https://maps.app.goo.gl/moB5HmAdWiA3pDqu5
    `
let message = window.encodeURIComponent(whatsappMessage)
window.open(`https://api.whatsapp.com/send?phone=+2${clientNumberOnSpot}&text=${message}`, "_blank")

   } else if (feedback == "arrived") {
    var whatsappMessage= `Welcome to Bodyfit EMS Egypt !
    We are glad you have arrived to start your membership with us. Our team is here to help you achieve your fitness goals and ensure you have a positive experience.Please feel free to share any comments or suggestions you may have. Thank you for choosing us.
    `
let message = window.encodeURIComponent(whatsappMessage)
window.open(`https://api.whatsapp.com/send?phone=+2${clientNumberOnSpot}&text=${message}`, "_blank")

   } 
  setFeedback("")
  setFeedbackDate("")
  setFeedbackTime("")
  setFeedbackLocation("")
  setFeedbackNotes("")
  setActiveClient(null)
 if(user.user.role == "sales") {
    client({
    variables:{
      employee: user.user._id
    }
  })
}
    dispatch(ACTION_LOADING_SPINNER_RESET())
})
}

if(clientLoading) {
  return(
    <div className="adminContent">
    <DataSpinner />
    </div>
  )
}


 return (
        <div className="adminContent column" style={{position:"relative"}}>
            {returnToHomeScreen()}

            <div onClick={() => {
              dispatch(ACTION_PHONE_NUMBER_RESET())
              setClientModal("Client")
          
          }} className="addUserIcon" >
          <FontAwesomeIcon
                className="addUserI"
                icon={faPlus}
          />
          </div>


          <Modal
            isOpen={feedbackModel}
            style={{
              overlay: {
                position:"fixed",
                zIndex:"99999999"
            },
              content: {
                position: "fixed",
                zIndex: "10000",
                height: "100%",
                backdropFilter: "blur(5px)",
                WebkitBackdropFilter: "blur(5px)",
                borderRadius: "none",
                width: "auto",
                top: "0",
                left: "0", 
                right: "0",
                bottom: "0",
                border: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <div style={{height: "100%", width:"70vw"}} className="addCampaignClass">
            <FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => setFeedbackModal(false)}
              />
              <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
              }} className="campaignContainer">
                    
                    <div className="auth-label">
                        Feed back
                    </div>
                <div
                  role="combobox"
                  aria-haspopup="listbox"
                  aria-owns="react-autowhatever-1"
                  aria-controls="react-autowhatever-1"
                  aria-expanded="false"
                >

                  
<Dropdown
                options={[...feedbackOptions]}
                onChange={(choice) => {
                  setFeedbackDate("")
                    setFeedbackTime("")
                    setFeedbackLocation("")
                    setFeedback(choice.value)}
                }
                value={feedback}
                controlClassName="auth-input"
                
              />

                </div>
       { (feedback == "followUp") ? (  
         <>      
              <div className="auth-label">
                Date
              </div>
              <DayPickerInput
                classNames={{
                  container: "react-autosuggest__containerCampaign",
                  overlay: "",
                  overlayWrapper: clickOutsideDayPickerEnd
                    ? "react-autosuggest__input_hide"
                    : "",
                }}
                dayPickerProps={{ disabledDays: { before: new Date() } }}
                inputProps={{
                  className: "auth-input",
                  style: {
                    color: "rgb(74, 144, 226)",
                  },
                }}
                formatDate={formatDate}
                parseDate={parseDate}
                onDayChange={(day) =>
                  setFeedbackDate(day)
                }
                format="L"
                //value={adminAppointmentDate}
                placeholder="Feedback date"
              />

{ feedbackDate && <><div className="auth-label">
                Time
              </div>
              <Dropdown
                options={timeOptions()}
                onChange={(choice) =>
                  setFeedbackTime(choice.value)
                }
                value={feedbackTime}
                controlClassName="auth-input"
                
              />
              </>}
              </>
              ) : feedback == "booked" || feedback == "followToBook" ? (
                <>  

                <div className="auth-label">
                  Location
                </div>
                <Dropdown
                  options={locationOptions()}
                  onChange={(choice) =>
                    setFeedbackLocation(choice.value)
                  }
                  value={feedbackLocation}
                  controlClassName="auth-input"
                  
                />    
               
                { feedbackLocation && (
                <> 
                <div className="auth-label">
                  Date
                </div>
                <DayPickerInput
                  classNames={{
                    container: "react-autosuggest__containerCampaign",
                    overlay: "",
                    overlayWrapper: clickOutsideDayPickerEnd
                      ? "react-autosuggest__input_hide"
                      : "",
                  }}
                  dayPickerProps={{ disabledDays: { before: new Date() } }}
                  inputProps={{
                    className: "auth-input",
                    style: {
                      color: "rgb(74, 144, 226)",
                    },
                  }}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  onDayChange={(day) =>
                    setFeedbackDate(day)
                  }
                  format="L"
                  //value={adminAppointmentDate}
                  placeholder="Feedback date"
                />
                </> 
                )}
  
  { feedbackDate && <><div className="auth-label">
                  Time
                </div>
                <Dropdown
                menuClassName="underMenu"
                  options={timeOptions()}
                  onChange={(choice) =>
                    setFeedbackTime(choice.value)
                  }
                  value={feedbackTime}
                  controlClassName="auth-input"
                  
                />
                </>}
                
                </>
              ) : null}

<div className="form-collection textarea" style={{width:"100%"}}>
            <div className="auth-label">Notes</div>
              <textarea
                onChange={(e) => setFeedbackNotes(e.target.value)}
                value={feedbackNotes}
                type="text"
                placeholder="Notes"
                className="auth-input textarea-input"
              />
          </div>
                
              </div> 
              
              {(( feedback == "booked" && feedbackDate && feedbackTime && feedbackLocation) || (feedback == "followUp" && feedbackDate && feedbackTime) || (feedback == "followToBook" && feedbackDate && feedbackTime && feedbackLocation) || (feedback == "overPrices") || (feedback == "noAnswer") || (feedback == "noShow") || (feedback == "arrived") || (feedback == "notInterested") || (feedback == "medical")|| (feedback == "underAge")|| (feedback == "willCallUs")|| (feedback == "lowClass")|| (feedback == "askAboutPrices")|| (feedback == "alreadyMember")|| (feedback == "wrongNumber")|| (feedback == "overWeight") || (feedback == "switchedOff") || (feedback == "travell") || (feedback == "renew") || (feedback == "cancelled") || (feedback == "repeated") || (feedback == "willVisitTheBranch") ||  (feedback == "callBack") || (feedback == "farAway") || (feedback == "whatsappSend") || (feedback == "askAboutJob")) &&  (
              <div className="auth-button addUser" style={{display:"flex", justifyContent:"center", alignItems:"center"}} onClick={addUserFeedback}>
                            <span style={{margin:"0 3px", color:"white"}}>Save </span>
                            </div> )}
            </div>
            
          </Modal>


          {editClientModal && (
              
              <AddClientModal 
              
                clientModal={editClientModal}
                setClientModal={setEditClientModal}
                clientName={clientName}
                setClientName={setClientName}
                setClientEmail={setClientEmail}
                clientEmail={clientEmail}
                inviteUser={inviteUser}
                inviteManyUsers={inviteManyUsers}
                emailAlreadyRegistered={emailAlreadyRegistered}
                invalidEmail={invalidEmail}
                setEmailAlreadyRegistered={setEmailAlreadyRegistered}
                setClientMobile={setClientMobile}
                clientMobile={clientMobile}
                setClientLocation={setClientLocation}
                clientLocation={clientLocation}
                contactPersonName={contactPersonName}
                setContactPersonName={setContactPersonName}
                updateClientFunc={updateClientFunc}
                clientType={clientType}
                setClientType={setClientType}
                // avatar props
                takeAPhotoSelected={takeAPhotoSelected}
                changeTakeAPhotoSelected={changeTakeAPhotoSelected}
                webcamURI={webcamURI}
                changeWebcamURI={changeWebcamURI}
                imageUploaded={imageUploaded}
                changeImageUploaded={changeImageUploaded}
                imagePreviewAvailable={imagePreviewAvailable}
                changeImagePreviewAvailable={changeImagePreviewAvailable}
                handleDeletedPreviewImage={handleDeletedPreviewImage}
                handleImageUploaded={handleImageUploaded}
                addProfilePhotoClicked={addProfilePhotoClicked}
                setAddProfileClicked={setAddProfileClicked}
              
              />
  
              )}

            <AddClientModal 
                user={user}
                clientModal={clientModal}
                setClientModal={setClientModal}
                setClientName={setClientName}
                clientName={clientName}
                setClientEmail={setClientEmail}
                clientEmail={clientEmail}
                inviteUser={inviteUser}
                inviteManyUsers={inviteManyUsers}
                dataArr={dataArr}
                setDataArr={setDataArr}
                many={many}
                setMany={setMany}
                emailAlreadyRegistered={emailAlreadyRegistered}
                invalidEmail={invalidEmail}
                setEmailAlreadyRegistered={setEmailAlreadyRegistered}
                setClientMobile={setClientMobile}
                clientMobile={clientMobile}
                setClientLocation={setClientLocation}
                clientLocation={clientLocation}
                contactPersonName={contactPersonName}
                setContactPersonName={setContactPersonName}
                updateClientFunc={updateClientFunc}
                clientType={clientType}
                setClientType={setClientType}
                // avatar props
                takeAPhotoSelected={takeAPhotoSelected}
                changeTakeAPhotoSelected={changeTakeAPhotoSelected}
                webcamURI={webcamURI}
                changeWebcamURI={changeWebcamURI}
                imageUploaded={imageUploaded}
                changeImageUploaded={changeImageUploaded}
                imagePreviewAvailable={imagePreviewAvailable}
                changeImagePreviewAvailable={changeImagePreviewAvailable}
                handleDeletedPreviewImage={handleDeletedPreviewImage}
                handleImageUploaded={handleImageUploaded}
                addProfilePhotoClicked={addProfilePhotoClicked}
                setAddProfileClicked={setAddProfileClicked}
                
                />
              {user && user.user.role !=="marketing" ? (             

                activeClient ? activeClient == "Not Found" ? ( <div className="goodJob">Not Found, <br/> <span style={{cursor:"pointer", color:"skyblue"}} onClick={() => {
                    setActiveClient(clientData.client)
                }}>Reload.</span></div>) : (
                  <>
                  <AccountHeader editUser={editClient} deleteClientFunc={deleteClientFunc} selectedAccount={activeClient} type="client" />
 
                    {activeClient.feedback ? activeClient.feedback.length > 0 ? (
                      <>
                      <div className="auth-button addUser" onClick={() => setFeedbackModal(true)}>
                          Add Feedback
                        </div>
                      {[...activeClient.feedback].reverse().map((feedback, i) => (
                        <>                      
                        <div key={i} className="auditExpanded auditReports">
                    <div style={{display:"flex", justifyContent:"space-between"}} className="userSquareHeader">
                    <div style={{
                      display:"flex",
                      justifyContent:"center",
                      alignItems:"center"
                    }}
                    className="areaBar"
                    >
                    <span>{feedback.name}</span>
                    </div>
                    <div style={{
                      display:"flex",
                      justifyContent:"center",
                      alignItems:"center",
                      flexDirection:"column"
                    }}
                    className="areaBar"
                    >
                    {feedback.date && feedback.createdAt && feedback.date !== feedback.createdAt ? <span>Next call : {moment.unix(feedback.date/1000).subtract(3, "hours").format("MMMM DD, YYYY hh:mm A")}</span>: null} 
                    <span>Created at : {feedback.createdAt && moment.unix(feedback.createdAt/1000).subtract(3, "hours").format("MMMM DD, YYYY hh:mm A")}</span>
                    </div>                     
                </div>
                <div className="areaContainerOn">
                <UserFeedback feedback={feedback} />
                </div>
                </div>
                </>
                      ))}
                     </> 
                    ) : <div className="auth-button addUser" onClick={() => setFeedbackModal(true)}>
                          Add Feedback
                        </div> : (
                    <DataSpinner />
                    )
                    }

                  </>
                ) : <div className="goodJob"> Good Job, <br/> Relax, OR <span style={{cursor:"pointer", color:"skyblue"}} onClick={() => user && user.user.role == "sales" ? client({variables:{employee: user.user._id}}) : null}>Reload.</span></div>
                ) : (
                  <></>
                )}

{ activeClient == "Not Found" || !activeClient ? <img src="https://i.ibb.co/qmLPmfb/Whats-App-Image-2024-02-10-at-4-58-29-PM.jpg" style={{marginTop:"50px"}} height={"80%"} className="ahmedImg" /> : null
}        </div>
    )
}

export default AdminCampaign