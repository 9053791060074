import React, { useEffect, useState } from "react";
import "./index.scss"
import Dropdown from "react-dropdown";
import moment from "moment"
import staffFeedbackQuery from "../../../graphql/queries/feedbacks/staffFeedbackQuery";
import { useLazyQuery } from "@apollo/react-hooks";
import { DataSpinner, DataSpinner as RenderSitesLoader } from "../../resuable/spinner/dataSpinner";
import DataTable from 'react-data-table-component';
import { DateRangePicker } from "react-date-range";


const AdminDashboard = (props) => {

    const {
        user,
        staff,
        allUsersRefetch,
        allSales,
        searchNumber
    } = props 

    const staffColumn = [
        // {
        //     name: 'staff',
        //     cell: row => (
        //       <img
        //       onClick={() => subScreen(row)}
        //             className="adminAvatar"
        //             src={row.image ? row.image : blank}
        //             alt={
        //               row.name
        //             }
        //           />
        //     ),
        //     width: "50px"

            
        // },
{
    name: 'staff',
    selector: row => row.employee,
    width:"200px"

},
{
  name: 'first call',
  selector: row => row.firstCall,
  width:"200px"

},{
  name: 'last call',
  selector: row => row.lastCall,
  width:"200px"

},
// {
//   name: 'Total time',
//   cell: row => <div>{moment.utc((moment(row.lastCall).diff(moment(row.firstCall), "days"))).format('HH:mm')} hours</div>,
//   width:"200px"

// },
{
  name: 'AHT',
  cell: row => <div style={{color: Number(moment.utc((moment(row.lastCall).diff(moment(row.firstCall))/row.all)).format('mm')) <= 5 ? "green" : "red"}}>{moment.utc((moment(row.lastCall).diff(moment(row.firstCall))/row.all)).format('mm')} minutes</div>,
  width:"200px"

},
{
    name: 'All calls', 
    sortable: true,
    id:"count",
    selector: row => row.all,
    width: "100px" 

    
},
{
     name: "booked",
cell: row => <div style={{color: Number(row.booked) <= 10 ? "red" : "green"}}>{row.booked} </div>

},
{
     name: "arrived",
cell: row => <div style={{color: Number(row.arrived) <= 10 ? "red" : "green"}}>{row.arrived} </div>

},
{
     name: "noShow",
cell: row => <div style={{color: Number(row.noShow) <= 10 ? "red" : "green"}}>{row.noShow} </div>

},
{
     name: "noAnswer",
cell: row => <div style={{color: Number(row.noAnswer) <= 10 ? "red" : "green"}}>{row.noAnswer} </div>

},
{
     name: "followUp",
cell: row => <div style={{color: Number(row.followUp) <= 10 ? "red" : "green"}}>{row.followUp} </div>

},
{
     name: "overPrices",
cell: row => <div style={{color: Number(row.overPrices) <= 10 ? "red" : "green"}}>{row.overPrices} </div>

},
{
     name: "notInterested",
cell: row => <div style={{color: Number(row.notInterested) <= 10 ? "red" : "green"}}>{row.notInterested} </div>

},
{
  name: "joinedAnotherEms",
cell: row => <div style={{color: Number(row.joinedAnotherEms) <= 10 ? "red" : "green"}}>{row.joinedAnotherEms} </div>

},
{
     name: "medical",
cell: row => <div style={{color: Number(row.medical) <= 10 ? "red" : "green"}}>{row.medical} </div>

},
{
     name: "underAge",
cell: row => <div style={{color: Number(row.underAge) <= 10 ? "red" : "green"}}>{row.underAge} </div>

},
{
     name: "willCallUs",
cell: row => <div style={{color: Number(row.willCallUs) <= 10 ? "red" : "green"}}>{row.willCallUs} </div>

},
{
     name: "lowClass",
cell: row => <div style={{color: Number(row.lowClass) <= 10 ? "red" : "green"}}>{row.lowClass} </div>

},
{
     name: "askAboutPrices",
cell: row => <div style={{color: Number(row.askAboutPrices) <= 10 ? "red" : "green"}}>{row.askAboutPrices} </div>

},
{
     name: "askAboutJob",
cell: row => <div style={{color: Number(row.askAboutJob) <= 10 ? "red" : "green"}}>{row.askAboutJob} </div>

},
{
     name: "alreadyMember",
cell: row => <div style={{color: Number(row.alreadyMember) <= 10 ? "red" : "green"}}>{row.alreadyMember} </div>

},
{
     name: "wrongNumber",
cell: row => <div style={{color: Number(row.wrongNumber) <= 10 ? "red" : "green"}}>{row.wrongNumber} </div>

},
{
     name: "overWeight",
cell: row => <div style={{color: Number(row.overWeight) <= 10 ? "red" : "green"}}>{row.overWeight} </div>

},
{
     name: "switchedOff",
cell: row => <div style={{color: Number(row.switchedOff) <= 10 ? "red" : "green"}}>{row.switchedOff} </div>

},
{
     name: "followToBook",
cell: row => <div style={{color: Number(row.followToBook) <= 10 ? "red" : "green"}}>{row.followToBook} </div>

},
{
     name: "travell",
cell: row => <div style={{color: Number(row.travell) <= 10 ? "red" : "green"}}>{row.travell} </div>

},
{
     name: "renew",
cell: row => <div style={{color: Number(row.renew) <= 10 ? "red" : "green"}}>{row.renew} </div>

},
{
     name: "cancelled",
cell: row => <div style={{color: Number(row.cancelled) <= 10 ? "red" : "green"}}>{row.cancelled} </div>

},
{
     name: "repeated",
cell: row => <div style={{color: Number(row.repeated) <= 10 ? "red" : "green"}}>{row.repeated} </div>

},
{
     name: "willVisitTheBranch",
cell: row => <div style={{color: Number(row.willVisitTheBranch) <= 10 ? "red" : "green"}}>{row.willVisitTheBranch} </div>

},
{
     name: "callBack",
cell: row => <div style={{color: Number(row.callBack) <= 10 ? "red" : "green"}}>{row.callBack} </div>

},
{
     name: "farAway", 
cell: row => <div style={{color: Number(row.farAway) <= 10 ? "red" : "green"}}>{row.farAway} </div>

},
{
     name: "whatsappSend",
cell: row => <div style={{color: Number(row.whatsappSend) <= 10 ? "red" : "green"}}>{row.whatsappSend} </div>

}
]

const numbersColumn = [
{
 name: 'staff',
 selector: row => row.employee,
 width:"200px"

},
{
 name: 'All calls',
 sortable: true,
 id:"count",
 selector: row => row.all,
 width: "100px" 

 
},

{
     name: "leeds",
   cell: row => <div style={{color: Number(row.leeds) <= 10 ? "red" : "green"}}>{row.leeds} </div>
   
   },
   {
     name: "website",
   cell: row => <div style={{color: Number(row.website) <= 10 ? "red" : "green"}}>{row.website} </div>
   
   },
   {
     name: "unactive",
   cell: row => <div style={{color: Number(row.unactive) <= 10 ? "red" : "green"}}>{row.unactive} </div>
   
   },
   {
     name: "income",
   cell: row => <div style={{color: Number(row.income) <= 10 ? "red" : "green"}}>{row.income} </div>
   
   },
   {
     name: "whatsapp",
   cell: row => <div style={{color: Number(row.whatsapp) <= 10 ? "red" : "green"}}>{row.whatsapp} </div>
   
   },
   {
     name: "walkIn",
   cell: row => <div style={{color: Number(row.walkIn) <= 10 ? "red" : "green"}}>{row.walkIn} </div>
   
   },
   {
     name: "recommendation",
   cell: row => <div style={{color: Number(row.recommendation) <= 10 ? "red" : "green"}}>{row.recommendation} </div>
   
   },
   {
     name: "old",
   cell: row => <div style={{color: Number(row.old) <= 10 ? "red" : "green"}}>{row.old} </div>
   
   }, 
   {
     name: "expired",
   cell: row => <div style={{color: Number(row.expired) <= 10 ? "red" : "green"}}>{row.expired} </div>
   
   },
   {
     name: "lastSession",
   cell: row => <div style={{color: Number(row.lastSession) <= 10 ? "red" : "green"}}>{row.lastSession} </div>
   
   },
{
  name: "booked",
cell: row => <div style={{color: Number(row.booked) <= 10 ? "red" : "green"}}>{row.booked} </div>

},
{
  name: "arrived",
cell: row => <div style={{color: Number(row.arrived) <= 10 ? "red" : "green"}}>{row.arrived} </div>

},
{
  name: "noShow",
cell: row => <div style={{color: Number(row.noShow) <= 10 ? "red" : "green"}}>{row.noShow} </div>

},
{
  name: "noAnswer",
cell: row => <div style={{color: Number(row.noAnswer) <= 10 ? "red" : "green"}}>{row.noAnswer} </div>

},
{
  name: "followUp",
cell: row => <div style={{color: Number(row.followUp) <= 10 ? "red" : "green"}}>{row.followUp} </div>

},
{
  name: "overPrices",
cell: row => <div style={{color: Number(row.overPrices) <= 10 ? "red" : "green"}}>{row.overPrices} </div>

},
{
  name: "notInterested",
cell: row => <div style={{color: Number(row.notInterested) <= 10 ? "red" : "green"}}>{row.notInterested} </div>

},
{
  name: "joinedAnotherEms",
cell: row => <div style={{color: Number(row.joinedAnotherEms) <= 10 ? "red" : "green"}}>{row.joinedAnotherEms} </div>

},
{
  name: "medical",
cell: row => <div style={{color: Number(row.medical) <= 10 ? "red" : "green"}}>{row.medical} </div>

},
{
  name: "underAge",
cell: row => <div style={{color: Number(row.underAge) <= 10 ? "red" : "green"}}>{row.underAge} </div>

},
{
  name: "willCallUs",
cell: row => <div style={{color: Number(row.willCallUs) <= 10 ? "red" : "green"}}>{row.willCallUs} </div>

},
{
  name: "lowClass",
cell: row => <div style={{color: Number(row.lowClass) <= 10 ? "red" : "green"}}>{row.lowClass} </div>

},
{
  name: "askAboutPrices",
cell: row => <div style={{color: Number(row.askAboutPrices) <= 10 ? "red" : "green"}}>{row.askAboutPrices} </div>

},
{
  name: "askAboutJob",
cell: row => <div style={{color: Number(row.askAboutJob) <= 10 ? "red" : "green"}}>{row.askAboutJob} </div>

},
{
  name: "alreadyMember",
cell: row => <div style={{color: Number(row.alreadyMember) <= 10 ? "red" : "green"}}>{row.alreadyMember} </div>

},
{
  name: "wrongNumber",
cell: row => <div style={{color: Number(row.wrongNumber) <= 10 ? "red" : "green"}}>{row.wrongNumber} </div>

},
{
  name: "overWeight",
cell: row => <div style={{color: Number(row.overWeight) <= 10 ? "red" : "green"}}>{row.overWeight} </div>

},
{
  name: "switchedOff",
cell: row => <div style={{color: Number(row.switchedOff) <= 10 ? "red" : "green"}}>{row.switchedOff} </div>

},
{
  name: "followToBook",
cell: row => <div style={{color: Number(row.followToBook) <= 10 ? "red" : "green"}}>{row.followToBook} </div>

},
{
  name: "travell",
cell: row => <div style={{color: Number(row.travell) <= 10 ? "red" : "green"}}>{row.travell} </div>

},
{
  name: "renew",
cell: row => <div style={{color: Number(row.renew) <= 10 ? "red" : "green"}}>{row.renew} </div>

},
{
  name: "cancelled",
cell: row => <div style={{color: Number(row.cancelled) <= 10 ? "red" : "green"}}>{row.cancelled} </div>

},
{
  name: "repeated",
cell: row => <div style={{color: Number(row.repeated) <= 10 ? "red" : "green"}}>{row.repeated} </div>

},
{
  name: "willVisitTheBranch",
cell: row => <div style={{color: Number(row.willVisitTheBranch) <= 10 ? "red" : "green"}}>{row.willVisitTheBranch} </div>

},
{
  name: "callBack",
cell: row => <div style={{color: Number(row.callBack) <= 10 ? "red" : "green"}}>{row.callBack} </div>

},
{
  name: "farAway", 
cell: row => <div style={{color: Number(row.farAway) <= 10 ? "red" : "green"}}>{row.farAway} </div>

},
{
  name: "whatsappSend",
cell: row => <div style={{color: Number(row.whatsappSend) <= 10 ? "red" : "green"}}>{row.whatsappSend} </div>

},
]



const allNumbersColumn = [
     {
      name: 'staff',
      selector: row => row.employee,
      width:"200px",
      sortable:true

     
     },
     {
          name :"number",
          selector: row => row.number,
     },
     {
          name :"name",
          selector: row => row.name,
          sortable:true

     },
     {
          name :"state",
          selector: row => row.state,
          sortable:true
     },
     {
          name :"date",
          selector: row => row.date,
     },
     {
          name :"nextCall",
          selector: row => row.nextCall,
          sortable:true

     },
     {
          name :"location",
          selector: row => row.location,
          sortable:true
     },
     {
          name :"type",
          selector: row => row.type,
          sortable:true

     },
     ]


    const [staffData, setStaffData] = useState(null)
    const [dashboardActive, setDashboardActive] = useState(null)
    const [employee, setEmployee] = useState(null)
    
     const [feedback, setFeedback] = useState(null)
     const [allFeedbacks, setAllFeedbacks] = useState([
"booked",
"followUp",
"overPrices",
"notInterested",
"joinedAnotherEms",
"arrived",
"noShow",
"noAnswer",
"medical",
"underAge",
"willCallUs",
"lowClass",
"askAboutPrices",
"askAboutJob",
"alreadyMember",
"wrongNumber",
"overWeight",
"switchedOff",
"followToBook",
"travell",
"renew",
"cancelled",
"repeated",
"willVisitTheBranch",
"callBack",
"farAway",
"whatsappSend",
     ])

     const [state, setState] = useState([
      {
        startDate: moment().startOf("day").toDate(),
        endDate: moment().endOf("day").toDate(),
        key: 'selection'
      }
    ])

    const [createdAt, setCreatedAt] = useState(moment().startOf("day").toDate())
    const [createdTo, setCreatedTo] = useState(moment().endOf("day").toDate())
    const [createdAtActive, setCreatedAtActive] = useState("Today")

    const [createdAtNumbers, setCreatedAtNumbers] = useState(moment().startOf("day").toDate())
    const [createdAtNumbersActive, setCreatedAtNumbersActive] = useState("Today")


    const [allStaffData, setAllStaffData] = useState(null)
    const [allNumbersData, setAllNumbersData] = useState(null)
    const [allFeedbacksDataArr, setAllFeedbacksArrData] = useState(null)
    const [feedbacksData, setFeedbacksData] = useState(null)
    const [
        staffFeedback,
        { loading: staffFeedbackLoading, data:staffFeedbackData, refetch: staffFeedbackRefetch, error: staffFeedbackError },
      ] = useLazyQuery(staffFeedbackQuery,{
        fetchPolicy: 'no-cache'
      });

      useEffect(()=>{
        if(state) {
          console.log(state)
        setCreatedAt(moment(state[0].startDate).startOf("day").toDate()) 
        setCreatedAt(moment(state[0].startDate).startOf("day").toDate()) 
        setCreatedTo(moment(state[0].endDate).endOf("day").toDate())
        }
      },[state])

        const [
          allFeedbacksFunc,
          { loading: allFeedbacksLoading, data:allFeedbacksData, refetch: allFeedbacksRefetch, error: allFeedbacksError },
        ] = useLazyQuery(staffFeedbackQuery,{
          fetchPolicy: 'no-cache'
        });

            useEffect(() => {  
            if(employee && createdAt && createdTo) {
              console.log(createdAt,
                createdTo)
            staffFeedback({
            variables:{
                employee: employee?.value !== "All" ? employee?.value : null,
                createdAt: createdAt,
                createdTo: createdTo
            }
            })
            }
            },[employee, createdAt, createdTo])


            useEffect(() => { 
               if(feedback && createdAt&& createdTo) {
               allFeedbacksFunc({
               variables:{
                   feedback: feedback?.value,
                   createdAt: createdAt,
                   createdTo: createdTo,
                   employee: user.user.role == "admin" ? null : user.user.name
               }
               }).then(res => console.log(res, 111111))
               } 
               },[feedback, createdAt, createdTo])

            useEffect(() => {
            if(staffFeedbackData) { 
              console.log(staffFeedbackData, 1231)
                setAllStaffData(staffFeedbackData?.staffFeedback)
            }
            },[staffFeedbackData])


            useEffect(() => {
               if(allFeedbacksData) {
                   setAllFeedbacksArrData(allFeedbacksData?.staffFeedback)
               }
               },[allFeedbacksData])


            useEffect(() => {
                if(allStaffData?.length) {
                   setStaffData(allStaffData.map(x => {
                    console.log(x, 111)
                    let newX = {...x}
                    newX.employee = allSales.find(y => y.value == x.employee)?.label || "Admin"
                   newX.all = Number(x.booked) +
                              Number(x.followUp) +
                              Number(x.overPrices) +
                              Number(x.notInterested) +
                              Number(x.joinedAnotherEms) +
                              Number(x.arrived) +
                              Number(x.noShow) +
                              Number(x.noAnswer) +
                              Number(x.medical) +
                              Number(x.underAge) +
                              Number(x.willCallUs) +
                              Number(x.lowClass) +
                              Number(x.askAboutPrices) +
                              Number(x.askAboutJob) +
                              Number(x.alreadyMember) +
                              Number(x.wrongNumber) +
                              Number(x.overWeight) +
                              Number(x.switchedOff) +
                              Number(x.followToBook) +
                              Number(x.travell) +
                              Number(x.renew) +
                              Number(x.cancelled) +
                              Number(x.repeated) +
                              Number(x.willVisitTheBranch) +
                              Number(x.callBack) +
                              Number(x.farAway) +
                              Number(x.whatsappSend) 
                    return newX 
                   }))
                }  else {
                  setStaffData([])
                }
            },[allStaffData])

            useEffect(() => {
               if(allFeedbacksDataArr?.length) {
                    setAllNumbersData(JSON.parse(allFeedbacksDataArr[0].name))

                  setFeedbacksData(allFeedbacksDataArr.map(x => {
                   let newX = {...x}
                   newX.employee = x.employee
                   newX.all =Number(x.booked) +
                             Number(x.followUp) +
                             Number(x.overPrices) +
                             Number(x.notInterested) +
                             Number(x.joinedAnotherEms) +
                             Number(x.arrived) +
                             Number(x.noShow) +
                             Number(x.noAnswer) +
                             Number(x.medical) +
                             Number(x.underAge) +
                             Number(x.willCallUs) +
                             Number(x.lowClass) +
                             Number(x.askAboutPrices) +
                             Number(x.askAboutJob) +
                             Number(x.alreadyMember) +
                             Number(x.wrongNumber) +
                             Number(x.overWeight) +
                             Number(x.switchedOff) +
                             Number(x.followToBook) +
                             Number(x.travell) +
                             Number(x.renew) +
                             Number(x.cancelled) +
                             Number(x.repeated) +
                             Number(x.willVisitTheBranch) +
                             Number(x.callBack) +
                             Number(x.farAway) +
                             Number(x.whatsappSend) 
                   return newX 
                  }))
               } else {
                setAllNumbersData([])
setFeedbacksData([])
               }
           },[allFeedbacksDataArr])


    return (
        <div className="adminContent column" style={{position:"relative"}}>
            <div style={{width:"100%", display:"flex", flexDirection:"column", alignItems:"center"}}>
            {user && allSales && <>
              <DateRangePicker
                editableDateInputs={true}
                onChange={item => setState([item.selection])} 
                moveRangeOnFirstSelection={false}
                ranges={state}
                direction="horizontal"   
                months={1}
                calendarFocus="backwards"        
              /> 
            <div className="form-label">Feedbacks</div>
            <div style={{width:"100%"}}>
              <Dropdown
            key="name"
            options={ user?.user?.role == "admin" || user?.user?.email == "rawnaa.rona21@gmail.com" ? [{label:"All Staff", value:"All"}, ...allSales] : [...allSales?.filter(x => x.value == user.user._id)]}
            onChange={(choice) => {
            setEmployee(choice)
            }} 
            value={employee?.value}
            controlClassName="form-input"
            />  
            </div>
            </>}
            </div>
            <div style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                {/* <div style={{border: createdAtActive == "Month" ? "2px solid blue" : "1px solid lightblue"}} className="createdSelect" onClick={() => {
                    setCreatedAtActive("Month")
                    setCreatedAt(moment().startOf("month").toDate())
                }}>Month</div>
                <div style={{border: createdAtActive == "Week" ? "2px solid blue" : "1px solid lightblue"}} className="createdSelect" onClick={() => {
                    setCreatedAtActive("Week")
                    setCreatedAt(moment().startOf("week").toDate())
                }}>Week</div>
                <div style={{border: createdAtActive == "Yesterday" ? "2px solid blue" : "1px solid lightblue"}} className="createdSelect" onClick={() => {
                    setCreatedAtActive("Yesterday")
                    setCreatedAt(moment().subtract(1, 'days').startOf("day").toDate())
                }}>Yesterday</div>
                <div style={{border: createdAtActive == "Today" ? "2px solid blue" : "1px solid lightblue"}} className="createdSelect" onClick={() => {
                    setCreatedAtActive("Today")
                    setCreatedAt(moment().startOf("day").toDate())
                }}>Today</div>  */}
               
            </div>
           {staffFeedbackLoading ? <DataSpinner /> : staffData?.length ? <div style={{border:"2px solid gray", marginTop:"20px", marginBottom:"100px", borderRadius:"8px", width:"100%"}}>
             <DataTable
                columns={staffColumn}
                data={staffData || []}
                // progressPending={!staffFeedbackData}
                // progressComponent={RenderSitesLoader({small:"l"})}
                onRowClicked={(row) => setDashboardActive(row._id)}
            />
            </div> : <div style={{fontSize:"26px", textTransform:"uppercase", marginTop:"20px"}}>no record to display</div>}

            <div style={{width:"100%"}}>
            {user && allFeedbacks && 
            <>
            <div className="form-label">Numbers</div>
            <Dropdown
            key="name"
            options={ [{label:"Next calls (All Feedbacks)", value:"All"}, {label:"Created time (All Feedbacks)", value:"Old"}, ...allFeedbacks]}
            onChange={(choice) => {
            setFeedback(choice)
            }} 
            value={feedback?.value} 
            controlClassName="form-input"
            />            </>
          }  
            </div>
            {/* <div style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
            <div style={{border: createdAtNumbersActive == "Time" ? "2px solid blue" : "1px solid lightblue"}} className="createdSelect" onClick={() => {
                    setCreatedAtNumbersActive("Time")
                    setCreatedAtNumbers("time")
                }}>All Time</div>
                <div style={{border: createdAtNumbersActive == "Month" ? "2px solid blue" : "1px solid lightblue"}} className="createdSelect" onClick={() => {
                    setCreatedAtNumbersActive("Month")
                    setCreatedAtNumbers(moment().startOf("month").toDate())
                }}>Month</div>
                <div style={{border: createdAtNumbersActive == "Week" ? "2px solid blue" : "1px solid lightblue"}} className="createdSelect" onClick={() => {
                    setCreatedAtNumbersActive("Week")
                    setCreatedAtNumbers(moment().startOf("week").toDate())
                }}>Week</div>
                <div style={{border: createdAtNumbersActive == "Yesterday" ? "2px solid blue" : "1px solid lightblue"}} className="createdSelect" onClick={() => {
                    setCreatedAtNumbersActive("Yesterday")
                    setCreatedAtNumbers(moment().subtract(1, 'days').startOf("day").toDate())
                }}>Yesterday</div>
                <div style={{border: createdAtNumbersActive == "Today" ? "2px solid blue" : "1px solid lightblue"}} className="createdSelect" onClick={() => {
                    setCreatedAtNumbersActive("Today")
                    setCreatedAtNumbers(moment().startOf("day").toDate())
                }}>Today</div> 
            </div> */}
           {feedbacksData?.length ? <div style={{border:"2px solid gray", marginTop:"20px", borderRadius:"8px", width:"100%"}}>
             <DataTable
                columns={numbersColumn}
                data={feedbacksData || []}
                // progressPending={!staffFeedbackData}
                // progressComponent={RenderSitesLoader({small:"l"})}
               //  onRowClicked={(row) => setDashboardActive(row._id)} 
            />
            </div> : <div style={{fontSize:"26px", textTransform:"uppercase", marginTop:"20px"}}>no record to display</div>}

            {allNumbersData?.length ? <div style={{border:"2px solid gray", marginTop:"20px", borderRadius:"8px", marginTop:"50px", width:"100%"}}>
             <DataTable
                columns={allNumbersColumn}
                data={allNumbersData || []}
                pagination={true}
                filterServer={true}
                onRowClicked={(row) => searchNumber(row.number)}
                conditionalRowStyles={[ 
                    {
                    when: row => row,
                    style: {
                         cursor:"pointer"
                    }, 
                    },
                    ]}             
            // progressPending={!staffFeedbackData}
                // progressComponent={RenderSitesLoader({small:"l"})}
               //  onRowClicked={(row) => setDashboardActive(row._id)} 
            />
            </div> :null} 
        </div>  
    )
}

export default AdminDashboard 